import { Container , Header ,  Grid , Image ,Segment } from 'semantic-ui-react'
import './i18n';
import { useTranslation, Trans } from 'react-i18next';
import * as Images from './images/images.js'


const Info = () => {
    const { t , i18n } = useTranslation();
return (
<Container >
<Header as='h1' style={{fontSize : '2.5em'}}>
    {t('info.header')}
            <Header.Subheader>
              {t('info.subheader')}
            </Header.Subheader>
  </Header>

  <Segment> 
    <Grid stackable>
      <Grid.Row columns={3}>
        <Grid.Column>
          {t('info.text.0')}
        </Grid.Column>
        <Grid.Column >
          {t('info.text.1')}
        </Grid.Column>
        <Grid.Column >
          {t('info.text.2')}
        </Grid.Column>
      </Grid.Row>
    </Grid>
    
  </Segment>
</Container>
)
}
export default Info