import { Container , Header , Button , Menu , Grid , Image , Divider , Segment , Modal , Form } from 'semantic-ui-react'
import './i18n';
import { useTranslation, Trans } from 'react-i18next';
import Masonry , { ResponsiveMasonry } from 'react-responsive-masonry';
import * as Images from './images/images.js'
import parse from 'html-react-parser';
import {  Link, useNavigate } from "react-router-dom"

const ShopHome = ({ productList : products}) => {
    const { t , i18n } = useTranslation();
      const navigate = useNavigate();

    const handleNavigate = (prod) => {
        navigate(`${prod}`)
    }
    return (
        <Container id="shop"  style={{}}>
        <Header as='h1' style={{fontSize : '2.5em'}}>
            Shop
          </Header>
          <ResponsiveMasonry
            columnsCountBreakPoints={{350: 1, 600 : 2 , 750: 3}}
          >
          <Masonry 
            columns={4} 
            gutter='1em'
          >
            {Object.keys(products).map(( s ) => { 
                if(s !== 'notFound') {
                  return <Segment>
                    <Header as='h3' ><Link as='a' to={`${s}`}>{t(`shop.${s}.title`)}</Link></Header>
                    <Image src={products[s].image} size="medium" />
                    <p><i>{parse(t(`shop.${s}.desc`).substring(0,250))}{t(`shop.${s}.desc`).length > 250 ? '...' : ''}</i></p>
                    <Button as='a' onClick={() => handleNavigate(s)}>{t('menu.meerInfo')}</Button>
                  </Segment> 
                }
          })}
          </Masonry>
          </ResponsiveMasonry>

        </Container>
    )
}
export default ShopHome