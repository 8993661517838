import './App.css';
import { useState , useEffect } from 'react';
import 'semantic-ui-css/semantic.min.css'
import { Container , Header , Button , Menu , Grid , Image , Divider , Segment , Modal , Form , Message } from 'semantic-ui-react'
import './i18n';
import { useTranslation, Trans } from 'react-i18next';
import * as Images from './images/images.js'
import * as Files from './files/files.js'
import jwt_decode  from 'jwt-decode'
import { Route, Routes, Link , useNavigate} from "react-router-dom"
import Profiel from './Profiel';
import ShopHome from './ShopList';
import Shop from './Shop'
import Info from './Info'
import ResetPassword from './ResetPassword'
import Contact from './Contact'
import Admin from './Admin'

const lngs = {
  en: { nativeName: 'EN' },
  nl: { nativeName: 'NL' } ,
  fr : { nativeName: 'FR'}
};

const products = {
    atPrimePortable : { price : 199.95 , image : Images.portable} ,
    upatTech2 : { price : 449.95 , image : Images.tech } ,
    upatOptimus : { price : 579.95 , image : Images.tech} ,
    fleetcare : { price : 165.00 , image : Images.fleet} ,
    abo1MO : { price : 18.00 , image : Images.sim } ,
    abo3MO : { price : 51.00 , image : Images.sim  } ,
    abo6MO : { price : 99.00 , image : Images.sim  } ,
    abo12MO : { price : 192.00 , image : Images.sim  } ,
}

function App() {
  const { t , i18n } = useTranslation();
  const navigate = useNavigate();
  const [ resetPasswordModalOpen, setResetPasswordModalOpen ] = useState(false)
  const [ loginModalOpen , setLoginModalOpen ] = useState(false)
  const [ profileModalOpen , setProfileModalOpen ] = useState(false)
  const [ profileData , setProfileData ] = useState({})
  const [ isLoggedIn , setIsLoggedIn] = useState(false)
  const [ userData , setUserData ] = useState({})
  const userToken = localStorage.getItem('token') !== null ? jwt_decode(localStorage.getItem('token')) : null

  const logOff = () => {
    setIsLoggedIn(false)
    setProfileModalOpen(false)
    setLoginModalOpen(false)
    localStorage.removeItem('token')
  }
 
  const apiURL = (process.env.NODE_ENV === 'development') ? 'http://localhost/upat/unpack-and-track/server/' : `${process.env.PUBLIC_URL}/server/` 

  const verifyLogin = () => {
    if(userToken !== null) {
      setIsLoggedIn(true)
      setUserData(userToken)
      console.log(userToken)
    } else {
      setIsLoggedIn(false)
      setUserData({})
    }
  }

  useEffect(() => {
    verifyLogin()
  },[])

  const doLogin = (email,password) => {
    let myHeaders = new Headers();
    console.log (email,password)
    
    myHeaders.append("Content-Type", "application/json");

    let data = JSON.stringify({
      email: email ,
      password: password
    })

    let  requestOptions = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow' ,
      body : data
    };

    fetch( `${apiURL}login`, requestOptions)
      .then(response => response.json())
      .then(result => {
        if(result.data.newAccount) {
          alert(t('newAccount'))
        } else {
          if(result.error) {
          alert(t(result.data.message))
          } else {
            console.log(result.data.jwt)
            setIsLoggedIn(true)
            setLoginModalOpen(false)
            setUserData({
              email : result.data.user ,
              userid : result.data.id
            })
            localStorage.setItem('token',result.data.jwt)
            //navigate switchen op basis van User Type, want admin heeft normaalgezien geen profiel!
            if(result.data.role === 'client') {
              navigate(`profiel`)
            }
            
          }
        }
        
      }
      )
      .catch(error => console.log('error', error));
  }

  

  const onSubmit = (event)=>{
    event.preventDefault();
    const {target} = event;
    const formdata = Object.fromEntries(new FormData(target))
    doLogin(formdata.email,formdata.password)
  }

  const onResetPassword = (event) => {
    event.preventDefault();
    const {target} = event;
    const formdata = Object.fromEntries(new FormData(target))
    console.log(formdata.email)

    let myHeaders = new Headers();
    console.log (formdata.email)
    
    myHeaders.append("Content-Type", "application/json");

    let data = JSON.stringify({
      email: formdata.email,
    })

    let  requestOptions = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow' ,
      body : data
    };

    fetch( `${apiURL}setResetPassword`, requestOptions)
      .then(response => response.json())
      .then(result => {
        if(result.error) {
          alert(t(`profile.${result.data.message}`))
        } else {
          alert(t(`profile.${result.data.message}`))
        }
        setResetPasswordModalOpen(false)
      }
      )
      .catch(error => console.log('error', error));
  }

  return (
    <Container fluid className="App">
      <Menu fixed='top' stackable>
      <Container >
        <Menu.Item header>
          <Link to='/' >
              <Image size='mini' src={Images.upatLogo} style={{ marginRight: '1.5em' , display : 'inline' }} />
              Unpack & Track
          </Link>
        </Menu.Item>
        <Menu.Item ><Link to='shop'>Shop</Link></Menu.Item>
        <Menu.Item ><Link to='contact'>{t('contact.contact')}</Link></Menu.Item>
        {!isLoggedIn && 
          <Menu.Item onClick={() => setLoginModalOpen(true)}>Login</Menu.Item>
        }
        {isLoggedIn && userToken.role !== 'admin' && 
          <Menu.Item><Link to='profiel'>{t('profile.header')}</Link></Menu.Item>
        }
        {isLoggedIn && userToken.role === 'admin' &&
          <Menu.Item><Link to='admin'>Admin</Link></Menu.Item>
        }
        {isLoggedIn && 
          <Menu.Item onClick={() => logOff()}>{t('menu.logOff')}</Menu.Item>
        }
    	  <Menu.Menu position="right">
          {Object.keys(lngs).map((lng) => (
            <Menu.Item key={lng} style={{ fontWeight: i18n.resolvedLanguage === lng ? 'bold' : 'normal' }} type="submit" onClick={() => i18n.changeLanguage(lng)}>
              {lngs[lng].nativeName}
            </Menu.Item>
          ))}
        </Menu.Menu>
      </Container>
    </Menu>
    <Container id="info" text style={{ marginTop: '7em' }}>
        <Grid columns='equal'>
          <Grid.Column width={4}><Image src={Images.upatLogo} /></Grid.Column>
          <Grid.Column>
          <Header as='h1' style={{ fontSize : '2.5em'}}>
            Unpack And Track
          <Header.Subheader  style={{fontSize : '0.5em'}}>
            {t('header.sub')}
          </Header.Subheader>
        </Header>
          </Grid.Column>
        </Grid>
        <Divider />
        
    </Container>
    <Routes>
     
      <Route path='' element={<Info />} />
      <Route path='profiel' element={<Profiel />} />
      <Route path='shop' element={<ShopHome
        productList={products}
      />} />
      <Route path='shop/:id' element={<Shop
        productList={products}
      />} />
      <Route path="*" element={<Info />} />
      <Route path="contact" element={<Contact />} />
      <Route path="admin" element={<Admin />} />
      <Route path="resetPassword/:resetCode" element={<ResetPassword />} />
    </Routes>
    {/** MODALS **/}
          
      <Modal
        open={loginModalOpen}
        size='fullscreen'
      >
        <Modal.Header>Login</Modal.Header>
        <Modal.Content>
        <Form onSubmit={onSubmit}> 
          <Form.Field>
            <label>Email</label>
            <input placeholder='Email' name='email' />
          </Form.Field>
          <Form.Field>
            <label>Password</label>
            <input placeholder='Password' type="password" name='password' />
          </Form.Field>
          <Form.Button positive type="submit">Login</Form.Button>
        </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button primary onClick={() => {
            setLoginModalOpen(false)
            setResetPasswordModalOpen(true)
          }}>Reset Password</Button>
          <Button negative onClick={() => setLoginModalOpen(false)}>Cancel</Button>
        </Modal.Actions>
      </Modal> 

      <Modal
        open={resetPasswordModalOpen}
        size='fullscreen'
      >
        <Modal.Header>Reset Password</Modal.Header>
        <Modal.Content>
        <Form onSubmit={onResetPassword}> 
          <Form.Field>
            <label>Email</label>
            <input placeholder='Email' name='email' />
          </Form.Field>
          <Form.Button positive type="submit">Reset wachtwoord</Form.Button>
        </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={() => setResetPasswordModalOpen(false)}>Cancel</Button>
        </Modal.Actions>
      </Modal> 
      
      <Segment inverted vertical style={{ height: '5vh' , margin: '5em 0em 0em', padding: '5em 0em' }}>
            Unpack And Track - Bruggestraat 112 - 8930 Menen - <a href={Files.toc}>Terms & Conditions</a>
          </Segment>
    </Container>
  );
}

export default App;
