import { useParams , useNavigate} from "react-router-dom"
import { Container , Grid , Header , Form  } from 'semantic-ui-react';
import './i18n';
import { useTranslation, Trans } from 'react-i18next';

const ResetPassword = () => {
    const navigate = useNavigate();
    const apiURL = (process.env.NODE_ENV === 'development') ? 'http://localhost/upat/unpack-and-track/server/' : `${process.env.PUBLIC_URL}/server/` 
    const { t , i18n } = useTranslation();
    const { resetCode } = useParams()

    const onResetPassword = (event) => {
        event.preventDefault();
        const {target} = event;
        const formdata = Object.fromEntries(new FormData(target))
        if(formdata.newPW !== formdata.repeatNewPW) {
            alert(t('profile.ne'))
        } else {
            doResetPassword(formdata.newPW , resetCode)
        }
        console.log(formdata)
        console.log(resetCode)
    }

    const doResetPassword = (newpw , checkCode) => {  
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        var raw = JSON.stringify({
            token : checkCode ,
            newPassword : newpw
          });
          
          const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
          };
          
          fetch(`${apiURL}getResetPassword/`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if(!result.error) {
                    alert(t(`profile.${result.data.message}`))
                    navigate('/')
                } else {
                    alert(t(`profile.${result.data.message}`))
                }
            })
            .catch(error => console.log('error', error));
    }

    return (
        <Container>
            <Grid>
                <Grid.Row columns={1}>
                    <Grid.Column>
                        <Header as='h1'>{t('profile.reset')}</Header>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns='equal'>
                    
                    <Grid.Column>
                        <Form 
                        onSubmit={onResetPassword}> 
                        <Form.Field>
            <label>{t('profile.newPW')}</label>
            <Form.Input 
                placeholder={t('profile.newPW')} 
                name='newPW' 
                type='password' 
            />
          </Form.Field>
          <Form.Field 
           
          >
            <label>{t('profile.repeatNewPW')}</label>
            <Form.Input 
                placeholder={t('profile.repeatNewPW')}
                name='repeatNewPW'
                type='password' 
            />
          </Form.Field>
          <Form.Button positive type="submit">{t('profile.reset')}</Form.Button>
        </Form>
                    </Grid.Column>
                </Grid.Row>
            </Grid>

        </Container>
    )
}
export default ResetPassword