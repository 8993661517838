import { useParams , useNavigate} from "react-router-dom"
import { Container , Grid , Header , Form, Divider  } from 'semantic-ui-react';
import './i18n';
import { useTranslation, Trans } from 'react-i18next';

const Contact = () => {
    const navigate = useNavigate();
    const apiURL = (process.env.NODE_ENV === 'development') ? 'http://localhost/upat/unpack-and-track/server/' : `${process.env.PUBLIC_URL}/server/` 
    const { t , i18n } = useTranslation();
  

    const onSend = (event) => {
        event.preventDefault();
        const {target} = event;
        const formdata = Object.fromEntries(new FormData(target))
        console.log(formdata)
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "email": formdata.contactEmail,
            "message": formdata.contactMessage
          });
          
          var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
          };
          
          fetch(`${apiURL}sendContactForm/`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if(!result.error) {
                    alert(t(`${result.data.message}`))
                    navigate('/')
                } else {
                    alert(t(`${result.data.message}`))
                }
            })
            .catch(error => console.log('error', error));
    }


    return (
        <Container>
            <Grid>
                <Grid.Row columns={1}>
                    <Grid.Column>
                        <Header as='h1'>{t('contact.intro')}</Header>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns='equal'>
                    
                    <Grid.Column>
                        <Form 
                        onSubmit={onSend}> 
                        <Form.Field>
                        <label>{t('contact.yourEmail')}</label>
            <Form.Input 
                placeholder={t('contact.yourEmail')} 
                name='contactEmail' 
                type='email' 
            />
          </Form.Field>
          <Form.Field>
            <label>{t('contact.yourMessage')}</label>
            <Form.TextArea 
                placeholder={t('contact.yourMessage')}
                name='contactMessage'

            />
          </Form.Field>
          <Form.Button positive type="submit">{t('contact.send')}</Form.Button>
        </Form>
        </Grid.Column>
        </Grid.Row>
        </Grid>
        <Divider />
        <Grid>
      
            <Grid.Row columns='equal'><Grid.Column>Bruggestraat 112</Grid.Column></Grid.Row>
            <Grid.Row columns='equal'><Grid.Column>BE-8930 Menen</Grid.Column></Grid.Row>
            <Grid.Row columns='equal'><Grid.Column>Tel : +32 50 950 912</Grid.Column></Grid.Row>
            <Grid.Row columns='equal'><Grid.Column>info@unpack-and-track.com</Grid.Column></Grid.Row>
            <Grid.Row columns='equal'><Grid.Column>VAT BE0898 104 885</Grid.Column></Grid.Row>
        </Grid>

        </Container>
    )
}
export default Contact