import isAdmin from "./isAdmin";
import './i18n';
import { useState , useEffect } from 'react';
import { useTranslation, Trans, composeInitialProps } from 'react-i18next';
import jwt_decode  from 'jwt-decode'
import { useNavigate} from "react-router-dom"

import { Container , Grid , Header, Segment , Icon,  Table , Form , Pagination , Button, Modal , Checkbox, Label, Divider } from 'semantic-ui-react';
import withAuth from "./withAuth";
const Admin = () => {
    const { t , i18n } = useTranslation();
    const [isAdmin , setIsAdmin] = useState(false)
    const apiURL = (process.env.NODE_ENV === 'development') ? 'http://localhost/upat/unpack-and-track/server/' : `${process.env.PUBLIC_URL}/server/` 
    const userToken = localStorage.getItem('token') !== null ? jwt_decode(localStorage.getItem('token')) : null
    const navigate = useNavigate();
    useEffect(() => {
        readAdmin()
        //console.log(readAdmin())
        //setIsAdmin(readAdmin())
    },[])

    const formExcludes = [ 'trackers' , 'role' ]
    const itemsPerPage = 10
   
    const [ resultList , setResultList ] = useState()
    const [ ap , sap ] = useState(1)
    const [ resultSet , setResultSet ] = useState([])
    const [ searchDisabled , setSearchDisabled] = useState(true)
    const [ search , setSearch ] = useState('') 
    const [ userData , setUserData ] = useState([])
    const [ userModalOpen , setUserModalOpen ] = useState(false)
    const [ editMode , setEditMode ] = useState(false)
    const [ editString , setEditString ] = useState('Bekijk')
    const [ resetDisabled , setResetDisabled] = useState({ disabled : false , label : 'Reset wachtwoord'})

    useEffect(() => {
        let editLabel = editMode ? 'Bewerk' : 'Bekijk'
        setEditString(editLabel)
    },[editMode])

    const doSearch = () => {
        console.log(search)
        sap(1)
        let myHeaders = new Headers();
         
        myHeaders.append("Content-Type", "application/json");
        const auth = localStorage.getItem('token') !== null ? `Bearer ${localStorage.getItem('token')}` : null
        myHeaders.append("Authorization", auth);
    
        let  requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow' ,
        };
    
        fetch( `${apiURL}searchUsers?searchString=${search}`, requestOptions)
              .then(response => response.json())
              .then(result => {
                    //console.log(result)
                    setResultList(result.userList)
                    let firstSet = result.userList.slice(0, 10);
                    setResultSet(firstSet)
              }
              )
              .catch(error => console.log('error', error));
    }

    const onType = (event , { name , value }) => {
        setSearch(value)
    }

    useEffect(() => {
        search.length >= 3 ? setSearchDisabled(false) : setSearchDisabled(true) 
    },[search])


    const readAdmin = () => {
        let myHeaders = new Headers();
         
        myHeaders.append("Content-Type", "application/json");
        const auth = localStorage.getItem('token') !== null ? `Bearer ${localStorage.getItem('token')}` : null
        myHeaders.append("Authorization", auth);
    
        let  requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow' ,
        };
    
        fetch( `${apiURL}isAdmin`, requestOptions)
              .then(response => response.json())
              .then(result => {
                setIsAdmin(!result.error)
                if(result.error) {
                    navigate('/')
                }
              }
              )
              .catch(error => console.log('error', error));
        }
    
    // Check geldigheid van token + admin > cf getUserData() + extra check met jwt decode
    //Admin acties : zoeken + profiel laden
    // Profiel : trackers toevoegen/verwijderen
    // Data aanpassen : user Form
    // Wachtwoord reset versturen : copy/paste van link voorzien
    // Wactwoord reset verplichten : flag newAccount toggle

        const handlePaginationChange = (event , { activePage } ) => {
            sap(activePage)
            let ind = ( activePage - 1 ) * itemsPerPage
            let set = resultList.slice(ind, ind + itemsPerPage);
            console.log(set)
            setResultSet(set)
        }

        const getUserData = ( obj ) => {
            let myHeaders = new Headers();
             
            myHeaders.append("Content-Type", "application/json");
            const auth = localStorage.getItem('token') !== null ? `Bearer ${localStorage.getItem('token')}` : null
            myHeaders.append("Authorization", auth);
        
            const params = new URLSearchParams(obj);
        
            let  requestOptions = {
              method: 'GET',
              headers: myHeaders,
              redirect: 'follow' ,
            };
        
            fetch( `${apiURL}getUserData?${params}`, requestOptions)
              .then(response => response.json())
              .then(result => {
                console.log(result.data)
                setResetDisabled({
                    disabled : false , 
                    label : 'Reset wachtwoord'
                })
                setUserModalOpen(true)
                setEditString('Bekijk')
                setEditMode(false)
                //setUserData(result.data)
                setUserData(result.data)
              }
              )
              .catch(error => console.log('error', error));
          }


          const resetPassword = (email) => {
                setResetDisabled({
                    disabled : true , 
                    label : 'Resetting...' ,
                    success : false ,
                })
                var raw = JSON.stringify({
                    "email": email
                });
              
                var requestOptions = {
                    method: 'POST',
                    body: raw,
                    redirect: 'follow'
                };
              
              fetch(`${apiURL}setResetPassword`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if(!result.error) { 
                        var counter = 10;
                        setResetDisabled({
                            disabled : true , 
                            success : true ,
                            label : `Reset geslaagd! (${counter})` ,
                            link : result.data.url ,
                            exp : result.data.exp
                        })
                        var newYearCountdown = setInterval(function(){
                          //console.log(counter);
                          counter--
                          setResetDisabled({
                            disabled : true , 
                            success : true ,
                            label : `Reset geslaagd! (${counter})` ,
                            link : result.data.url ,
                            exp : result.data.exp
                        })
                          if (counter === 0) {
                            setResetDisabled({
                                disabled : false , 
                                success : true ,
                                label : 'Reset wachtwoord' ,
                                link : result.data.url ,
                                exp : result.data.exp
                            })
                            clearInterval(newYearCountdown);
                          }
                        }, 1000);
                        /*
                        setTimeout(() => {
                            setResetDisabled({
                                disabled : false , 
                                success : true ,
                                label : 'Reset wachtwoord' ,
                                link : result.data.url ,
                                exp : result.data.exp
                            })
                        }, 10000)
                        */
                    }
                })
                .catch(error => console.log('error', error));
          }

    return (
        <Container>
            {isAdmin && 
            <Form
                onSubmit={doSearch}
            >
                <Grid>
                    <Grid.Row columns='equal'>
                        <Grid.Column>
                            <Form.Input 
                                placeholder='Zoek gebruikers...'
                                name='searchUsers'
                                onChange={onType}
                                value={search}
                                fluid 
                                icon={<Icon name='delete' link onClick={() => {
                                    setResultList()
                                    setSearch('')
                                }}/>}
                            />
                        </Grid.Column>
                        <Grid.Column width={2}>
                            <Form.Button 
                                disabled={searchDisabled}
                                positive
                                type='submit'  
                            >
                                Zoek
                            </Form.Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Form>
            }
            {!!resultList && 
                    <Table celled>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>  
                                    Totaal : {resultList.length}
                                </Table.HeaderCell>  
                                <Table.HeaderCell colSpan={2}>
                                    <Pagination 
                                        //defaultActivePage={1} 
                                        activePage={ap}
                                        totalPages={Math.ceil(resultList.length / itemsPerPage)} 
                                        onPageChange={handlePaginationChange}
                                    />
                                </Table.HeaderCell>   
                                <Table.HeaderCell>
                                    <Button fluid positive disabled>New User</Button>
                                </Table.HeaderCell>                            
                            </Table.Row>
                            <Table.Row>
                                <Table.HeaderCell>Naam</Table.HeaderCell>
                                <Table.HeaderCell>Email</Table.HeaderCell>
                                <Table.HeaderCell>Account</Table.HeaderCell>
                                <Table.HeaderCell>Actie</Table.HeaderCell>
                            </Table.Row>
                           
                        </Table.Header>
                        <Table.Body>
                            {resultSet.map((o) => {
                                return <Table.Row>
                                    <Table.Cell>{o.firstname ||= ''} {o.lastname ||= ''}</Table.Cell>
                                    <Table.Cell>{o.email}</Table.Cell>
                                    <Table.Cell>{o.account_reference}</Table.Cell>
                                    <Table.Cell><Button 
                                        primary
                                        onClick={() => getUserData({ userid : o.id , email : o.email })}
                                    >Open</Button></Table.Cell>
                                </Table.Row>
                            })}
                        </Table.Body>
                        <Table.Footer>
                            <Table.Row>
                                <Table.HeaderCell>  
                                    Totaal : {resultList.length}
                                </Table.HeaderCell>  
                                <Table.HeaderCell colSpan={2}>
                                    <Pagination 
                                        //defaultActivePage={1} 
                                        activePage={ap}
                                        totalPages={Math.ceil(resultList.length / itemsPerPage)} 
                                        onPageChange={handlePaginationChange}
                                    />
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    <Button fluid positive disabled>New User</Button>
                                </Table.HeaderCell>                                
                            </Table.Row>
                        </Table.Footer>
                    </Table>
                    
               
            }
            <Modal
                size='large'
                open={userModalOpen}
                onClose={() => setUserModalOpen(false)}
              >
                <Modal.Header> 
                    {editString} dit profiel.
                </Modal.Header>
                <Modal.Content scrolling>
                <Header as='h3'>User</Header>
                    <Form as={Form} 
                        onSubmit={e => console.log(e)}
                    >
                        {Object.keys(userData).map((x) => {
                             if(!formExcludes.includes(x)) {
                                return <Form.Field>
                                    <label>{x}:</label>
                                        {editMode && 
                                        <Form.Input 
                                            placeholder={x}
                                            defaultValue={userData[x]}
                                        />
                                        }
                                        {!editMode &&
                                            <span>{userData[x]}</span>
                                        }
                            </Form.Field>
                          } 
                        })
                        }
                    </Form>
                    <Divider />
                    {
                        !!userData['trackers'] &&
                        <span>
                            <Header as='h3'>Trackers</Header>
                            {userData['trackers'].map((t) => {
                                return <Segment>{t}</Segment>
                            })}
                        </span>
                    }
                    <Divider />
                    {
                        !!userData['role'] &&
                        <span>
                            <Header as='h3'>Type gebruiker:</Header>
                            <Segment>{userData['role'] === 'A' ? 'Administrator' : 'Klant'}</Segment>
                        </span>
                    }
                </Modal.Content>
                <Modal.Actions>
                    {
                        !!resetDisabled.success &&
                        <Segment textAlign="left" style={{overflowWrap: 'break-word'}}>
                            Reset geslaagd:<br />
                            URL: {resetDisabled.link}<br />
                            Vervalt op: {resetDisabled.exp}
                        </Segment>
                    }
                    
                    <Checkbox 
                        disabled
                        label='Editmode'
                        toggle 
                        onChange={(e , data ) => {
                            setEditMode(data.checked)
                        }}
                        style={{float : 'left'}}
                    />

                    <Button.Group style={{marginRight : '5px'}}>
                        <Button 
                            disabled={resetDisabled.disabled}
                            primary 
                            onClick={() => resetPassword(userData.email)}
                        >
                            {resetDisabled.label}
                        </Button>
                    </Button.Group>
                    <Button.Group style={{marginRight : '5px'}}>
                        <Button positive disabled={!editMode} type='submit'>
                            Save
                        </Button>
                  <Button 
                    negative 
                    onClick={() => {
                        setUserData({})
                        setUserModalOpen(false)
                    }}>
                    Cancel
                  </Button>
                        </Button.Group>
                 
                  
                </Modal.Actions>
              </Modal>
            
        </Container>    
    )
}
export default withAuth(Admin)