import withAuth from "./withAuth";
import './i18n';
import { useState , useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import jwt_decode  from 'jwt-decode'
import { Container , Grid , Header, Segment , Icon, Divider, Table } from 'semantic-ui-react';
const Profiel = () => {
    const { t , i18n } = useTranslation();
    const [ profileData , setProfileData ] = useState({})
    const apiURL = (process.env.NODE_ENV === 'development') ? 'http://localhost/upat/unpack-and-track/server/' : `${process.env.PUBLIC_URL}/server/` 
    const userToken = localStorage.getItem('token') !== null ? jwt_decode(localStorage.getItem('token')) : null
    
    useEffect(() => {
        getUserData()
      },[])

    const getUserData = () => {
        let myHeaders = new Headers();
         
        myHeaders.append("Content-Type", "application/json");
        const auth = localStorage.getItem('token') !== null ? `Bearer ${localStorage.getItem('token')}` : null
        myHeaders.append("Authorization", auth);
    
        const params = new URLSearchParams(userToken);
    
        let  requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow' ,
        };
    
        fetch( `${apiURL}getUserData?${params}`, requestOptions)
          .then(response => response.json())
          .then(result => {
            console.log(result)
            setProfileData(result.data)
          }
          )
          .catch(error => console.log('error', error));
      }
    return (
        <Container>
            <Grid>
                <Grid.Row columns='equal'>
                    <Grid.Column width={4}>
                        <Segment>
                        <Header as='h2'>{t('profile.myTrackers')}</Header>
                        <Divider />
                        <a href={`https://login.trackserver.co.uk/?account=${profileData.account}&password=`} target="_blank" rel="noreferrer">
                            <Icon link name='map marker alternate' size='massive' />
                        </a>    
                        </Segment>
                    </Grid.Column>
                    <Grid.Column>
                        <Segment>
                        <Header as='h2'>{t('profile.header')}</Header>
                        <Divider />
                        <Table celled divided>
                            {
                                Object.keys(profileData).map(( k ) => {
                                    if(typeof(profileData[k]) === 'string') {
                                        return <Table.Row>
                                            <Table.Cell>{k}</Table.Cell>
                                            <Table.Cell>{profileData[k]}</Table.Cell>
                                        </Table.Row>
                                    }
                                    if(typeof(profileData[k]) === 'object') {
                                        return <Table.Row>
                                            <Table.Cell>{k}</Table.Cell>
                                            <Table.Cell>{profileData[k].join(' | ')}</Table.Cell>
                                        </Table.Row>
                                    }
                                })
                            } 
                        </Table>
                        </Segment>
                    </Grid.Column>
                </Grid.Row>
            </Grid>

        </Container>
        
    )
}
export default withAuth(Profiel)