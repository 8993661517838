import { useState } from 'react'
import { Container , Header , Grid , Image , Segment, Divider } from 'semantic-ui-react'
import './i18n';
import { useTranslation, Trans } from 'react-i18next';
import parse from 'html-react-parser';
import * as Images from './images/images.js'
import { useParams } from "react-router-dom"

const Shop = ({ productList : products }) => {
    const { t , i18n } = useTranslation();

    const { id } = useParams()
    const productId = products[id] !== undefined ? id : 'notFound'
    products['notFound'] = {
        price : 0.00 ,
        image : Images.upatLogo
    }

    return (
        <Container>
            <Header as='h1' style={{fontSize : '2.5em'}}>
                {t(`shop.${productId}.title`)}
            </Header>
            <Segment>
                <Grid stackable>
                    <Grid.Row columns='equal'>
                        <Grid.Column width={4}>
                            <Image src={products[productId].image} size="medium" />
                        </Grid.Column>
                        <Grid.Column>
                            {parse(t(`shop.${productId}.desc`))}    
                        </Grid.Column>   
                    </Grid.Row>
                    <Divider />
                    <Grid.Row columns='equal'>
                        <Grid.Column>
                            {t('shop.price')} : €{products[productId].price}
                        </Grid.Column>   
                    </Grid.Row>
                </Grid> 
        </Segment>
        </Container>
    )
}
export default Shop